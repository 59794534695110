import { Lightning, Utils } from '@lightningjs/sdk';
import { formatTime } from '../../../helpers';
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../../constants';
import { useProgress } from './hooks/useProgress';
import SeekablePlayerControlsV1 from './SeekablePlayerControlsV1';
export default class SeekablePlayerControlsV2 extends SeekablePlayerControlsV1 {
    // @ts-expect-error TODO: move this into separate components once we use V2 controls for VOD and FER
    set seekIconV2(seek) {
        var _a, _b, _c, _d;
        (_a = this.tag('SeekBar')) === null || _a === void 0 ? void 0 : _a.patch({
            BigCircle: undefined,
            SmallCircle: undefined,
            Metadata: undefined,
            Shader: undefined,
        });
        switch (seek) {
            case 0:
                (_b = this.tag('SeekBar')) === null || _b === void 0 ? void 0 : _b.patch({
                    Shader: {
                        rect: true,
                        w: 24,
                        h: 24,
                        x: 0,
                        y: 0,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            strokeColor: COLORS.white,
                            fillColor: COLORS.red,
                            stroke: 3,
                            radius: 12,
                        },
                    },
                });
                break;
            case -1:
            case -2:
            case -3:
            case 1:
            case 2:
            case 3: {
                const isRew = seek < 0;
                const direction = isRew ? -1 : 1;
                const start = isRew ? 0 : seek;
                const end = 3 - Math.abs(seek);
                const fill = Array(end).fill(0);
                const arrows = [1, 1, 1];
                arrows.splice(start, end, ...fill);
                (_c = this.tag('SeekBar')) === null || _c === void 0 ? void 0 : _c.patch({
                    Shader: {
                        rect: true,
                        w: 94,
                        h: 32,
                        x: 0,
                        y: -8,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            fillColor: COLORS.red,
                            stroke: 0,
                            radius: 16,
                        },
                    },
                    Metadata: {
                        Arrows: Object.assign({ x: isRew ? -30 : -23, y: 0 }, arrows.reduce((acc, type, index) => (Object.assign(Object.assign({}, acc), { [`Arrow${index}`]: {
                                src: Utils.asset('images/player/arrow.png'),
                                w: 17,
                                h: 15,
                                x: 17 * index,
                                color: type === 1 ? COLORS.white : COLORS.mediumGray4,
                                scaleX: direction,
                            } })), {})),
                        Time: {
                            y: -50,
                            color: COLORS.white,
                            mountX: 0.5,
                            text: {
                                fontSize: 28,
                                fontFace: FONT_FACE.regular,
                                textAlign: TEXT_ALIGN.center,
                            },
                        },
                    },
                });
                break;
            }
            case 10:
            case -10:
                (_d = this.tag('SeekBar')) === null || _d === void 0 ? void 0 : _d.patch({
                    Metadata: {
                        Arrow: {
                            y: -25,
                            mount: 0.5,
                            src: Utils.asset('images/player/jump.png'),
                            w: 30,
                            h: 34,
                            scaleX: seek < 0 ? 1 : -1,
                        },
                        Time: {
                            y: -31,
                            color: COLORS.white,
                            mountX: 0.5,
                            text: {
                                fontSize: 12,
                                fontFace: FONT_FACE.bold,
                                textAlign: TEXT_ALIGN.center,
                                text: 10,
                            },
                        },
                    },
                    Shader: {
                        rect: true,
                        w: 24,
                        h: 24,
                        x: 0,
                        y: 0,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            strokeColor: COLORS.white,
                            fillColor: COLORS.red,
                            stroke: 3,
                            radius: 12,
                        },
                    },
                });
                break;
            default:
                break;
        }
    }
    // @ts-expect-error Override this method once we use V2 controls for VOD and FER
    updateSeekBarTimerV2() {
        var _a;
        // TODO: move this into separate components once we use V2 controls for VOD and FER
        const [time, _, range] = useProgress().value;
        if (range) {
            const text = formatTime(Date.now() / 1000 - (range[1] - time));
            (_a = this.tag('Metadata.Time')) === null || _a === void 0 ? void 0 : _a.patch({
                text: {
                    text,
                },
            });
        }
    }
    updatePlayerControlsFocus() {
        var _a;
        (_a = this.tag('ControlsWrapper')) === null || _a === void 0 ? void 0 : _a.patch({
            PlayOrPause: {
                alpha: 1,
            },
            Progress: {
                CurrentBarBg: {
                    alpha: 0.5,
                },
                CurrentBar: {
                    alpha: 1,
                },
                SeekBar: {
                    Shader: {
                        shader: {
                            fillColor: COLORS.red,
                            stroke: 3,
                        },
                    },
                },
            },
            Timer: {
                alpha: 1,
            },
        });
    }
    updatePlayerControlsUnfocus() {
        var _a;
        (_a = this.tag('ControlsWrapper')) === null || _a === void 0 ? void 0 : _a.patch({
            PlayOrPause: {
                alpha: 0.55,
            },
            Progress: {
                CurrentBarBg: {
                    alpha: 0.275,
                },
                CurrentBar: {
                    alpha: 0.55,
                },
                SeekBar: {
                    Shader: {
                        shader: {
                            fillColor: COLORS.red3,
                            stroke: 0,
                        },
                    },
                },
            },
            Timer: {
                alpha: 0.55,
            },
        });
    }
}
