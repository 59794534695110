import BaseEventsPlayer from './BaseEventsPlayer';
import FERPlayerControls from '../../components/player/PlayerControls/FERPlayerControls';
import { PlayerStates } from '../../constants';
import { CONTENT_TYPE, PAGE_NAME } from '../../constants';
import { AdBreakDataEvent } from '../../player/model/event/AdBreakDataEvent';
import { Router } from '@lightningjs/sdk';
import { WithSeeking } from './hoc/WithSeeking';
import { WithPauseAds } from './hoc/WithPauseAds';
import { FERAnalyticsDelegate } from './delegates/analytics/FERAnalyticsDelegate';
import { WithEndCardFER } from './hoc/WithEndCard/WithEndCardFER';
import { WithTempPass } from './hoc/WithTempPass';
import { useProgress } from '../../components/player/PlayerControls/hooks/useProgress';
import TVPlatform from '../../lib/tv-platform';
class FERPlayer extends BaseEventsPlayer {
    constructor() {
        super(...arguments);
        this._log_tag = 'FER Player';
        this._stream_type = CONTENT_TYPE.FER;
        this._progress = useProgress();
    }
    static _template() {
        return super._template({
            Controls: { type: FERPlayerControls },
        });
    }
    set params(params) {
        this._params = params;
        this.pid = params === null || params === void 0 ? void 0 : params.pid;
    }
    _init() {
        super._init();
        this._analyticsDelegate = new FERAnalyticsDelegate(this);
    }
    _playerEventsHandler(event) {
        super._playerEventsHandler(event);
        if (event instanceof AdBreakDataEvent) {
            this._controls.addAdBreakPoints(event.adBreaks.filter(({ startTime }) => startTime > 0));
        }
    }
    _detach() {
        super._detach();
    }
    _setPlayerInActiveTimeout() { }
    _onStreamEnd() {
        var _a, _b;
        // Don't navigate back if FER endcard is displayed
        if (this._getState() === PlayerStates.PlayerEndcardFER)
            return;
        const prevRoute = (_b = (_a = Router.getHistory()) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.hash;
        if (prevRoute && prevRoute.includes(PAGE_NAME.replays.toLowerCase()))
            TVPlatform.historyBack();
        else
            Router.back();
    }
}
export default class extends WithPauseAds(WithSeeking(WithEndCardFER(WithTempPass(FERPlayer)))) {
}
